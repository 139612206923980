import React from 'react'
import Header from "../components/header"
import Post from '../components/post'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

export const query = graphql`
    query QueryB($tag: [String]) {
        allMarkdownRemark(
            filter: {frontmatter: {tags: {in: $tag}}}
            sort: {fields: frontmatter___date, order: DESC}
          ) {
            edges {
              node {
                frontmatter {
                  date(formatString: "MMMM Do, YYYY")
                  tags
                  title
                }
                fields {
                  slug
                }
              }
            }
          }
    }
  
`

const Tags = (props) => {
    console.log(props)

    let posts = props.data.allMarkdownRemark.edges.map(edge => {
        return(
            {
                slug: edge.node.fields.slug,
                title: edge.node.frontmatter.title,
                date: edge.node.frontmatter.date,
                tags: edge.node.frontmatter.tags
            }
        )
    })

    return(
        <>
        <SEO title={`Posts tagged: ${props.pageContext.tag}`}/>
        <Header/>
        <div className="container">
            <div className="col-sm-9">
                <h2 className="Tags__title">Posts tagged: {props.pageContext.tag}</h2>
                <p className="Tags__paragraph"><span>{posts.length}</span> posts found</p>
                <div>{posts.map(post => post ? <Post post={post} /> : null)}</div>
            </div>
        </div>
        </>
    )
}

export default Tags